'use-client';
import * as React from 'react';
import type { VerticalLineProps } from '../VerticalLine';
import type { IVerticalLineProps } from '../../../VerticalLine.types';
import VerticalLine from '../../VerticalLine';
import skinsStyle from './styles/VerticalNotchDashedLine.scss';
import { BaseVerticalLine } from './BaseVerticalLine';

const VerticalNotchDashedLineSkin: React.FC<VerticalLineProps> = props => (
  <BaseVerticalLine {...props} skinsStyle={skinsStyle} />
);

const VerticalNotchDashedLine: React.FC<
  Omit<IVerticalLineProps, 'skin'>
> = props => {
  return <VerticalLine {...props} skin={VerticalNotchDashedLineSkin} />;
};

export default VerticalNotchDashedLine;
